.page_params{
  padding: 1% 2%;
}
.wrap_in_btn{
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  align-items: center;
}
.multi_block{
  display: flex;
  flex-direction: column;
  gap: 25px;
}
h1{

}
.card_draggable{
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.wrap_list{
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.intro_simple{
  font-style: italic;
}
.wrap_input_setting{
  .in_wrap_input_setting{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
  }
}
.wrap_inputs_setting{
  display: flex;
  flex-direction: column;
  gap: 25px;
  &.loud{
    padding: 2rem;
  }
}
.input_type_setting, .select_type_setting{
  //border:solid #44A1AA 1px;
  border: none;
  background: #F5F4F8;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  height: 36px;
  width: 270px;
}
.textarea_type_setting{
  border: none;
  background: #F5F4F8;
  border-radius: 4px;
  height: 90px;
  width: 500px;
  resize: none;
  padding: 0.5rem;
}

.link_delete{
  font-weight: bold;
  text-decoration: underline;
  color: #A53577;
  &:hover{
    cursor: pointer;
  }
}